import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import loginSlice from "../slice/loginSlice";
import resetPasswordSlice from "../slice/resetPasswordSlice";
import registerSlice from "../slice/registerSlice";
import trailSubscriptionSlice from "../slice/trailSubscriptionSlice";
import createSubscriptionSlice from "../slice/createSubscriptionSlice";
import sendLocationSlice from "../slice/sendLocationSlice";
import geoLocationSlice from "../slice/geoLocationSlice";
import unsubscribeSlice from "../slice/unsubscribeSlice";
import locationListSlice from "../slice/locationListSlice";
import authenticateSlice from "../slice/authenticateSlice";
import updateSettingSlice from "../slice/updateSettingSlice";
import subscriptionDetailsSlice from "../slice/subscriptionDetailsSlice";
import adminDeleteUserSlice from "../slice/adminDeleteUserSlice";
import createNewAdminSlice from "../slice/createNewAdminSlice";
import adminUsersSlice from "../slice/adminUsersSlice";
import productListSlice from "../slice/productListSlice";
import standardSubscriptionSlice from "../slice/standardSubscriptionSlice";
import iPQualityScoreSlice from "../slice/iPQualityScoreSlice";
import settingBanIpSlice from "../slice/settingBanIpSlice";
import checkSettingBanIpSlice from "../slice/checkSettingBanIpSlice";
import getCurrencySlice from "../slice/getCurrencySlice";
import addEditCurrencySlice from "../slice/addEditCurrencySlice";
import deleteCurrencySlice from "../slice/deleteCurrencySlice";
import paymentIntentSlice from "../slice/paymentIntentSlice";
import subscriptionCreateSlice from "../slice/subscriptionCreateSlice";
import subscriptionSpreedlyCreateSlice from "../slice/subscriptionSpreedlyCreateSlice";
import validLocationKeySlice from "../slice/validLocationKeySlice";
import gclidCheckSlice from "../slice/gclidCheckSlice";
import getGclidDetailsSlice from "../slice/getGclidDetailsSlice";
import savegclidSlice from "../slice/savegclidSlice";
import paymentMethodSlice from "../slice/paymentMethodSlice";
import spreedlyPaymentIntentSlice from "../slice/spreedlyPaymentIntentSlice";
import getSendDetailsSlice from "../slice/getSendDetailsSlice";
import getIPIfySlice from "../slice/getIPIfySlice";
import spreedlyStorePaymentIntentSlice from "../slice/spreedlyStorePaymentIntentSlice";
import updateBillingMethodSlice from "../slice/updateBillingMethodSlice";
import locatePhoneNumberIPQSlice from "../slice/locatePhoneNumberIPQSlice";
import productListSingleSlice from "../slice/productListSingleSlice";
import updateUserLanguageSlice from "../slice/updateUserLanguageSlice";
import smsServicesSlice from "../slice/smsServicesSlice";
import subscriptionListSlice from "../slice/subscriptionListSlice";
import applePayValidationSlice from "../slice/applePayValidationSlice";
import getPhoneTrackingCurrencySlice from "../slice/getPhoneTrackingCurrencySlice";
import upsertSpreedlySlice from "../slice/upsertSpreedlySlice";
import upsertStripeSlice from "../slice/upsertStripeSlice";
import getSpreedlyCurrencySlice from "../slice/getSpreedlyCurrencySlice";
import getTrailCurrencySlice from "../slice/getTrailCurrencySlice";
import getSmsConfigSlice from "../slice/getSmsConfigSlice";
import solidGateRegisterSlice from "../slice/solidGate/solidGateRegisterSlice";
import solidGateIntentCreateSlice from "../slice/solidGate/solidGateIntentCreateSlice";
import solidGateSubscriptionCreateSlice from "../slice/solidGate/solidGateSubscriptionCreateSlice";
import solidGateProductListSlice from "../slice/solidGate/solidGateProductListSlice";
import solidGateUpdateMethodIntentSlice from "../slice/solidGate/solidGateUpdateMethodIntentSlice";
import solidGateUpdatePaymentMethodSlice from "../slice/solidGate/solidGateUpdatePaymentMethodSlice";
import solidGateIntentV2CreateSlice from "../slice/solidGate/solidGateIntentV2CreateSlice";
import solidGateV2SubscriptionCreateSlice from "../slice/solidGate/solidGateV2SubscriptionCreateSlice";

const store = configureStore({
  reducer: {
    authenticate: authenticateSlice,
    paymentIntent: paymentIntentSlice,
    loginData: loginSlice,
    resetPasswordData: resetPasswordSlice,
    registerData: registerSlice,
    trailSubscription: trailSubscriptionSlice,
    createSubscription: createSubscriptionSlice,
    standardSubscription: standardSubscriptionSlice,
    sendLocation: sendLocationSlice,
    geoLocation: geoLocationSlice,
    unsubscribe: unsubscribeSlice,
    locationList: locationListSlice,
    updateSetting: updateSettingSlice,
    subscriptionDetails: subscriptionDetailsSlice,
    deleteUser: adminDeleteUserSlice,
    createNewAdmin: createNewAdminSlice,
    getAdminUsers: adminUsersSlice,
    getProductList: productListSlice,
    iPQualityScore: iPQualityScoreSlice,
    settingBanIp: settingBanIpSlice,
    checkSettingBanIp: checkSettingBanIpSlice,
    getCurrencyData: getCurrencySlice,
    addEditCurrencyData: addEditCurrencySlice,
    deleteCurrencyData: deleteCurrencySlice,
    subscriptionCreateData: subscriptionCreateSlice,
    subscriptionSpreedlyCreateData: subscriptionSpreedlyCreateSlice,
    validLocationKeyData: validLocationKeySlice,
    callGclidCheck: gclidCheckSlice,
    getGclidDetails: getGclidDetailsSlice,
    savegclidData: savegclidSlice,
    paymentMethod: paymentMethodSlice,
    newPaymentIntent: spreedlyPaymentIntentSlice,
    sendDetailsData: getSendDetailsSlice,
    getIPIfy: getIPIfySlice,
    newStorePaymentIntent: spreedlyStorePaymentIntentSlice,
    locatePhoneNumberIPQS: locatePhoneNumberIPQSlice,
    updateBillingMethodData: updateBillingMethodSlice,
    getProductListSingle: productListSingleSlice,
    updateUserLanguage: updateUserLanguageSlice,
    smsConfig: smsServicesSlice,
    getSubscriptionListList: subscriptionListSlice,
    applePayValidation: applePayValidationSlice,
    upsertSpreedly: upsertSpreedlySlice,
    upsertStripe: upsertStripeSlice,
    getTrailCurrencyData: getTrailCurrencySlice,
    getSpreedlyCurrencyData: getSpreedlyCurrencySlice,
    getPhoneTrackingCurrencyData: getPhoneTrackingCurrencySlice,
    getSmsConfigData: getSmsConfigSlice,
    applePayValidation: applePayValidationSlice,
    solidGateRegister: solidGateRegisterSlice,
    solidGateIntent: solidGateIntentCreateSlice,
    solidGateSubscriptionCreate: solidGateSubscriptionCreateSlice,
    solidGateProductList: solidGateProductListSlice,
    solidGateUpdateMethodIntent: solidGateUpdateMethodIntentSlice,
    solidGateUpdatePaymentMethod: solidGateUpdatePaymentMethodSlice,
    solidGateV2Intent: solidGateIntentV2CreateSlice,
    solidGateV2SubscriptionCreate: solidGateV2SubscriptionCreateSlice
  },
  middleware: [thunk]
});

export default store;
